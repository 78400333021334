import { Tooltip } from 'antd';
import { SystemDescriptions } from "src/common/descriptions";
import { Sale } from 'src/common/models/sale';
import { formatToCurrency, formatToDisplayDatetime, } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.SALE.RECORDS
const commonDescriptions = SystemDescriptions.PAGES.COMMON

export enum ColumnsKeys {
    NO = 'key',
    DATETIME = 'datetime',
    CUSTOMER = 'shipTo',
    DOCUMENT = 'document',
    TOTAL = 'amount',
    AMOUNT_PENDING = 'outstanding',
    EXPIRATION_DATE = 'subbrand',
    STATUS = 'statusId',
    DTE_CERTIFICATED = 'isDteCertified',
    ACTIONS = 'actions',
    INVOICE_RECEIVER_ID = 'invoiceReceiverId',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.KEY,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.CUSTOMER,
        dataIndex: ColumnsKeys.CUSTOMER,
        key: ColumnsKeys.CUSTOMER,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DOCUMENT,
        dataIndex: ColumnsKeys.DOCUMENT,
        key: ColumnsKeys.DOCUMENT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.TOTAL,
        dataIndex: ColumnsKeys.TOTAL,
        key: ColumnsKeys.TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.OUTSTANDING,
        dataIndex: ColumnsKeys.AMOUNT_PENDING,
        key: ColumnsKeys.AMOUNT_PENDING,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.DTE_CERTIFIED,
        dataIndex: ColumnsKeys.DTE_CERTIFICATED,
        key: ColumnsKeys.DTE_CERTIFICATED,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value, record: Sale) => (
            record.isDteCertified
                ? <Tooltip title={`Aut. ${record?.authorization}`}>
                    {value === 1 ? commonDescriptions.YES : commonDescriptions.NO}
                </Tooltip>
                : value === 1 ? commonDescriptions.YES : commonDescriptions.NO
        )
    },
    {
        title: descriptions.TABLE_COLUMNS.INVOICE_RECEIVER_ID,
        dataIndex: ColumnsKeys.INVOICE_RECEIVER_ID,
        key: ColumnsKeys.INVOICE_RECEIVER_ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.STATUS,
        dataIndex: ColumnsKeys.STATUS,
        key: ColumnsKeys.STATUS,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => value === 1 ? descriptions.STATUS_PENDING : descriptions.STATUS_PAYED,
    },
];

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.TOTAL]: SummaryType.SUM,
    [ColumnsKeys.AMOUNT_PENDING]: SummaryType.SUM,
    [ColumnsKeys.NO]: SummaryType.COUNT,
}