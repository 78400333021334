import { Languages } from "src/common/descriptions/types";
import { SalePaymentRecordsDescriptions } from "./types";

const DescriptionsEnUs: SalePaymentRecordsDescriptions = {
    TITLE: "Sale Payment Records",
    TABLE: {
        ACTION_BUTTON: "Get Records",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Datetime",
        CUSTOMER: "Customer",
        DOCUMENT: "Document",
        TOTAL: "Total",
        SALE_TOTAL: "Sale Total",
        CASH: "Cash",
        TRANS: "Transaction",
        CREDIT_NOTE: "Credit Note",
        OUTSTANDING: "Outstanding",
        ACTIONS: "Actions",
    },
    ACTIONS: {
        VIEW_DETAIL: "View Detail",
        VIEW_SALE: "View Sale",
    },
    DETAIL_TITLE: "Sale Detail",
    EXPORT_DATA: "Export Data",
    PRINT: "Print",
}

const DescriptionsEsUs: SalePaymentRecordsDescriptions = {
    TITLE: "Pagos de Ventas",
    TABLE: {
        ACTION_BUTTON: "Ver Registros",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando datos",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Fecha",
        CUSTOMER: "Cliente",
        DOCUMENT: "Documento",
        TOTAL: "Total",
        SALE_TOTAL: "Total Venta",
        CASH: "Efectivo",
        TRANS: "Transacción",
        CREDIT_NOTE: "Nota crédito",
        OUTSTANDING: "Pendiente",
        ACTIONS: "Acciones",
    },
    ACTIONS: {
        VIEW_DETAIL: "Ver Detalle",
        VIEW_SALE: "Ver Venta",
    },
    DETAIL_TITLE: "Detalle de Venta",
    EXPORT_DATA: "Exportar Datos",
    PRINT: "Imprimir",
}

export const salePaymentsRecordsDictionary = {}
salePaymentsRecordsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
salePaymentsRecordsDictionary[Languages.SPANISH_US] = DescriptionsEsUs