import { Button, Row } from "antd";
import { Col8 } from "src/components/Columns";
import { PlusOutlined, UnorderedListOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import { Urls } from "src/common/urls";
import { SystemDescriptions } from "src/common/descriptions";
import { TitleSection } from "../TitleSection";

export const Shortcuts = () => {
    const navigate = useNavigate()

    const descriptions = SystemDescriptions.COMPONENTS.DASHBOARD.SHORTCUTS

    return (
        <>
            <TitleSection title={descriptions.TITLE} />
            <Row gutter={[8, 8]}>
                <Col8>
                    <Button
                        style={{ width: '100%', height: 50 }}
                        icon={<PlusOutlined rev={undefined} />}
                        onClick={() => navigate(Urls.FRONTEND.SALE.CREATE)}
                    >
                        {descriptions.CREATE_SALE}
                    </Button>
                </Col8>
                <Col8>
                    <Button
                        style={{ width: '100%', height: 50 }}
                        icon={<UnorderedListOutlined rev={undefined} />}
                        onClick={() => navigate(Urls.FRONTEND.SALE.RECORDS)}
                    >
                        {descriptions.SALES_RECORDS}
                    </Button>
                </Col8>
                <Col8>
                    <Button
                        style={{ width: '100%', height: 50 }}
                        icon={<OrderedListOutlined rev={undefined} />}
                        onClick={() => navigate(Urls.FRONTEND.PRODUCT.STOCK)}
                    >
                        {descriptions.PRODUCT_STOCK}
                    </Button>
                </Col8>
            </Row></>
    )
}
