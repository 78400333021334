import { Customer } from "src/common/models/customer";
import { ErrorAction, GetCustomerByIdSuccessAction, getCustomersSuccessAction } from "./action";
import { GET_CUSTOMER_BY_ID_API, GET_CUSTOMER_BY_ID_API_ERROR, GET_CUSTOMER_BY_ID_API_SUCCESS, GET_CUSTOMERS_API, GET_CUSTOMERS_API_ERROR, GET_CUSTOMERS_API_SUCCESS, UPSERT_CUSTOMER_API, UPSERT_CUSTOMER_API_SUCCESS } from "./actionTypes";
import { CustomerErrorOrigin, CustomerError } from "./types";
import { parseDataKey } from "src/common/util";
import { CREATE_PRODUCT_API_ERROR } from "src/features/Product/state/actionTypes";

export interface CustomerState {
    customers: Customer[]
    error: CustomerError | undefined
    upsertCustomerSuccess: boolean
    currentCustomer?: Customer
}

const initialState: CustomerState = {
    error: undefined,
    customers: [],
    upsertCustomerSuccess: false,
}

export default (state: CustomerState = initialState, action: any): CustomerState => {
    switch (action.type) {
        case GET_CUSTOMERS_API: {
            return {
                ...state,
                customers: [],
                error: undefined
            }
        }
        case GET_CUSTOMERS_API_SUCCESS: {
            const successAction = action as getCustomersSuccessAction;

            return {
                ...state,
                customers: parseDataKey(successAction.customers),
                error: undefined
            }
        }
        case GET_CUSTOMERS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                customers: [],
                error: {
                    type: CustomerErrorOrigin.GET_CUSTOMERS,
                    detail: errorAction.error
                }
            }
        }
        case UPSERT_CUSTOMER_API: {
            return {
                ...state,
                upsertCustomerSuccess: false,
                error: undefined
            }
        }
        case UPSERT_CUSTOMER_API_SUCCESS: {
            return {
                ...state,
                upsertCustomerSuccess: true,
                error: undefined,
            }
        }
        case CREATE_PRODUCT_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                upsertCustomerSuccess: false,
                error: {
                    type: CustomerErrorOrigin.UPSERT_CUSTOMER,
                    detail: errorAction.error,
                }
            }
        }
        case GET_CUSTOMER_BY_ID_API: {
            return {
                ...state,
                currentCustomer: undefined,
                error: undefined
            }
        }
        case GET_CUSTOMER_BY_ID_API_SUCCESS: {
            const successAction = action as GetCustomerByIdSuccessAction
            return {
                ...state,
                currentCustomer: successAction.customer,
            }
        }
        case GET_CUSTOMER_BY_ID_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: CustomerErrorOrigin.CREATE_CUSTOMER_BY_ID,
                    detail: errorAction.error,
                }
            }
        }
    }
    return state;
};
