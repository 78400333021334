import { getSessionData } from 'src/session/sessionStore'

const ACCESS_TOKEN_AUTH_TYPE = 'Bearer'
const LOGIN_TOKEN_AUTH_TYPE = 'Basic'

export const createToken = (accessToken?: string) => {
    const sessionData = getSessionData()
    const bearerToken = accessToken ? accessToken : sessionData.accessToken
    return `${ACCESS_TOKEN_AUTH_TYPE} ${bearerToken}`
}

export const createLoginToken = (username: string, password: string) => {
    const accessToken = generateToken([username, password])
    return `${LOGIN_TOKEN_AUTH_TYPE} ${accessToken}`
}

const generateToken = (params: string[]) => {
    const stringifiedParams = params.toString().replace(/,/g, ':')
    return btoa(stringifiedParams)
}

export const getErrorFromResponse = (error: any) => {
    return error?.response?.data?.error || error
}