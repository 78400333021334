import { Button, Col, Form, Input, Row, Select, Table } from "antd"
import { useEffect, useState } from "react"
import { AlignType } from "rc-table/lib/interface"
import * as GlobalStyles from "src/common/styles/styles"
import { ProductTransactionDetail, ProductTransactionType } from "../types"
import { Product } from "src/common/models/product"
import { v4 as uuid } from 'uuid';
import { SystemDescriptions } from "src/common/descriptions"
import { formatNumber } from "src/common/util"

type Props = {
    onCancel: () => void
    onAccept: (detail: ProductTransactionDetail[]) => void
    type: ProductTransactionType
    productsByBatch: Product[],
    productData: {
        brand: string
        subBrand: string
        category: string
    }
}

export const ProductBatchTransactionForm = (props: Props) => {

    const [transactionDetail, setTransactionDetail] = useState<ProductTransactionDetail[]>([])
    const [globalPresentations, setGlobalPresentations] = useState<any[]>([])
    const [presentationSelected, setPresentationSelected] = useState<boolean>(false)
    const [selectedPresentation, setSelectedPresentation] = useState<any>()

    const descriptions = SystemDescriptions.COMPONENTS.PRODUCT_TRANSACTION

    useEffect(() => {
        const newDetail: ProductTransactionDetail[] = props.productsByBatch.map(product => {
            return {
                productId: product.id!,
                product: product.name,
                meassureId: 0,
                meassure: '--',
                meassureSymbol: '--',
                qty: 0,
                unitPrice: props.type === ProductTransactionType.SALE ? 0 : product.coste,
                subtotal: 0,
                disscount: 0,
                total: 0,
                ref: uuid(),
                stock: product.stock,
                utility: 0,
                productType: product.productType,
                lastCoste: product.coste,
            }
        })

        setTransactionDetail(newDetail)
        setGlobalPresentations(getCommonPresentations())
    }, [props.productsByBatch])

    function getCommonPresentations() {
        if (props.productsByBatch.length === 0) return [];

        let commonPresentations = props.productsByBatch[0].presentations.map(p => p.meassureId);

        for (let i = 1; i < props.productsByBatch.length; i++) {
            const currentPresentations = props.productsByBatch[i].presentations.map(p => p.meassureId);
            commonPresentations = commonPresentations.filter(id => currentPresentations.includes(id));
        }

        const presentationMap = new Map();
        props.productsByBatch.forEach(product => {
            product.presentations.forEach(presentation => {
                if (!presentationMap.has(presentation.meassureId)) {
                    presentationMap.set(presentation.meassureId, presentation);
                }
            });
        });

        return commonPresentations.map(id => {
            const presentation = presentationMap.get(id)
            return {
                label: presentation.meassure,
                value: presentation.meassureId,
                symbol: presentation.meassureSymbol,
                qty: presentation.qty,
            }
        })
    }

    const [batchTotal, setBatchTotal] = useState(0)

    const handleKeyupQtyInput = (selectedProduct) => (e) => {
        const newQty = e.target.value.trim().length === 0 ? 0 : e.target.value

        let total = 0
        const newProductsByBatch = transactionDetail.map(product => {
            if (product.productId === selectedProduct.productId) {
                product.qty = parseInt(newQty)
                product.total = parseInt(newQty) * parseFloat(product.unitPrice.toString())
                product.subtotal = product.total
            }
            total += product.total
            return product
        })
        setBatchTotal(total)
        setTransactionDetail(newProductsByBatch)
    }

    const handleKeyupPriceInput = (selectedProduct) => (e) => {
        const newPrice = e.target.value.trim().length === 0 ? 0 : e.target.value

        let total = 0
        const newProductsByBatch = transactionDetail.map(product => {
            if (product.productId === selectedProduct.productId) {
                product.unitPrice = newPrice
                product.total = parseInt(product.qty.toString()) * parseFloat(newPrice)
                product.subtotal = product.total
            }
            total += product.total
            return product
        })
        setBatchTotal(total)
        setTransactionDetail(newProductsByBatch)
    }

    const handleInputFocus = (event: any) => event.target.select();

    const handleAssignQtyKeyup = (event) => {
        let total = 0
        const newProductsByBatch = transactionDetail.map(product => {
            product.qty = event.target.value
            product.total = parseInt(product.qty.toString()) * parseFloat(product.unitPrice.toString())
            product.subtotal = product.total
            total += product.total
            return product
        })
        setBatchTotal(total)
        setTransactionDetail(newProductsByBatch)
    }

    const handleAssignPriceKeyup = (event) => {
        let total = 0
        const newProductsByBatch = transactionDetail.map(product => {
            product.unitPrice = event.target.value
            product.total = parseInt(product.qty.toString()) * parseFloat(product.unitPrice.toString())
            product.subtotal = product.total
            total += product.total
            return product
        })
        setBatchTotal(total)
        setTransactionDetail(newProductsByBatch)
    }

    const renderTableProducts = () => {

        const columns = [
            {
                title: descriptions.BATCH_OPERATION.TABLE.ID,
                dataIndex: 'productId',
                key: 'productId',
                width: '10%',
                align: 'center' as AlignType
            },
            {
                title: descriptions.BATCH_OPERATION.TABLE.DESCRIPTION,
                dataIndex: 'product',
                key: 'product',
            },
            {
                title: descriptions.BATCH_OPERATION.TABLE.MEASSURE,
                dataIndex: 'meassure',
                key: 'meassure',
                width: '10%',
                align: 'center' as AlignType,
            },
            {
                title: descriptions.BATCH_OPERATION.TABLE.QTY,
                dataIndex: 'qty',
                key: 'qty',
                width: '10%',
                align: 'right' as AlignType,
                render: (_, product) => {
                    return <Input
                        className='text-right'
                        onChange={handleKeyupQtyInput(product)}
                        onFocus={handleInputFocus}
                        // prefix={descriptions?.campaignsTable.spendInputPrefix}
                        placeholder=""
                        defaultValue={0}
                        value={product.qty}
                        size="small"
                        status={
                            (
                                (parseInt(product.qty) > parseInt(product.stock)) && props.type === ProductTransactionType.SALE
                            )
                                ? 'error' : ''
                        }
                    />
                }
            },
            {
                title: descriptions.BATCH_OPERATION.TABLE.PRICE,
                dataIndex: 'price',
                key: 'price',
                width: '10%',
                align: 'right' as AlignType,
                render: (_, product) => {
                    return <Input
                        className='text-right'
                        onChange={handleKeyupPriceInput(product)}
                        onFocus={handleInputFocus}
                        // prefix={descriptions?.campaignsTable.spendInputPrefix}
                        defaultValue={product.unitPrice}
                        placeholder=""
                        value={product.unitPrice}
                        size="small"
                    />
                }
            },
            {
                title: descriptions.BATCH_OPERATION.TABLE.TOTAL,
                dataIndex: 'total',
                key: 'total',
                width: '10%',
                align: 'right' as AlignType,
                render: (_, product) => {
                    return <Input
                        className='text-right'
                        tabIndex={-1}
                        defaultValue={product.price}
                        placeholder=""
                        readOnly
                        value={product.total}
                        size="small"
                    />
                }
            },
        ];

        return <Table
            size="small"
            dataSource={[...transactionDetail]}
            columns={columns}
            pagination={false}
        />;
    }

    const getOkButtonStatus = () => {
        return props.type === ProductTransactionType.SALE
            ? transactionDetail.every(product => product.qty === 0) || transactionDetail.some(product => parseInt(product.qty.toString()) > parseInt((product.stock ?? 0).toString())) || !presentationSelected
            : false
    }

    const handleAcceptClick = () => {
        const itemsToAdd = transactionDetail.filter(detailItem => detailItem.qty > 0)
        let newDetail = [...itemsToAdd]

        if (props.type === ProductTransactionType.SALE) {
            newDetail = itemsToAdd.map(item => {
                const minimalQty: number = Number(item.qty) * Number(selectedPresentation.qty)
                const minimalPrice: number = (Number(item.unitPrice) * item.qty) / minimalQty
                item.utility = formatNumber(minimalQty * (minimalPrice - Number(item.lastCoste)), 4)

                return item
            })
        }

        props.onAccept(newDetail)
    }

    const handleAssignMeassureChange = (e) => {
        const foundPresentation = globalPresentations.find(candidate => Number(candidate.value) === e)

        const newProductsByBatch = transactionDetail.map(product => {
            product.meassureId = e
            product.meassure = foundPresentation.label
            product.meassureSymbol = foundPresentation.symbol
            return product
        })

        setTransactionDetail(newProductsByBatch)
        setPresentationSelected(true)
        setSelectedPresentation(foundPresentation)
    }

    const renderProductData = () => (
        <Row gutter={10}>

            <Col span={4} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.CATEGORY.LABEL}
                >
                    <Input
                        placeholder={descriptions.BATCH_OPERATION.CATEGORY.PLACEHOLDER}
                        value={props.productData.category}
                        readOnly size="small" />
                </Form.Item>
            </Col>

            <Col span={4} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.BRAND.LABEL}
                >
                    <Input
                        placeholder={descriptions.BATCH_OPERATION.BRAND.PLACEHOLDER}
                        value={props.productData.brand} readOnly size="small" />
                </Form.Item>
            </Col>

            <Col span={4} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.SUB_BRAND.LABEL}
                >
                    <Input
                        placeholder={descriptions.BATCH_OPERATION.SUB_BRAND.PLACEHOLDER}
                        value={props.productData.subBrand} readOnly size="small" />
                </Form.Item>
            </Col>

            <Col span={4} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.MEASSURE.LABEL}
                >
                    <Select
                        onChange={handleAssignMeassureChange}
                        placeholder={descriptions.BATCH_OPERATION.MEASSURE.PLACEHOLDER}
                        size="small"
                        options={globalPresentations}
                    />
                </Form.Item>
            </Col>

            <Col span={4} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.ASSIGN_QTY.LABEL}
                >
                    <Input
                        onChange={handleAssignQtyKeyup}
                        onFocus={handleInputFocus}
                        placeholder={descriptions.BATCH_OPERATION.ASSIGN_QTY.PLACEHOLDER}
                        size="small"
                    />
                </Form.Item>
            </Col>

            <Col span={4} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.ASSIGN_PRICE.LABEL}
                >
                    <Input
                        onChange={handleAssignPriceKeyup}
                        onFocus={handleInputFocus}
                        placeholder={descriptions.BATCH_OPERATION.ASSIGN_PRICE.PLACEHOLDER}
                        size="small"
                    />
                </Form.Item>
            </Col>

        </Row>
    )

    const renderTotals = () => (
        <Row gutter={10}>

            <Col span={21} >
            </Col>

            <Col span={3} >
                <Form.Item
                    label={descriptions.BATCH_OPERATION.TOTAL}
                    labelAlign='right'
                >
                    <Input
                        className="text-right"
                        placeholder={descriptions.BATCH_OPERATION.TOTAL}
                        value={batchTotal}
                        readOnly
                        size="small"
                    />
                </Form.Item>
            </Col>
        </Row>
    )

    const renderActionButtons = () => (
        <Row gutter={15} className='mt-3' >
            <Col span={6}>
                <GlobalStyles.ItemForm>
                    <Button
                        onClick={props.onCancel}
                        type="primary" danger className="login-form-button w-100 mt-2">
                        {descriptions.CANCEL}
                    </Button>
                </GlobalStyles.ItemForm>
            </Col>

            <Col span={12}>

            </Col>

            <Col span={6}>
                <GlobalStyles.ItemForm>
                    <Button
                        disabled={getOkButtonStatus()}
                        onClick={handleAcceptClick}
                        type="primary" className="login-form-button w-100 mt-2">
                        {descriptions.ACCEPT}
                    </Button>
                </GlobalStyles.ItemForm>
            </Col>
        </Row>
    )

    return (
        <Form
            layout="vertical"
        >
            {renderProductData()}
            {renderTableProducts()}
            {renderTotals()}
            {renderActionButtons()}
        </Form>
    )
}
