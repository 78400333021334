import { formatToCurrency, formatToDisplayDatetime } from "src/common/util";
import { searchableTableUtils } from "src/components/SearchableTable/utils";
import { SystemDescriptions } from "src/common/descriptions";
import { SummaryType } from "src/components/SearchableTable/types";

const descriptions = SystemDescriptions.PAGES.SALE.SALE_PAYMENTS

export enum ColumnsKeys {
    NO = 'key',
    DATETIME = 'createdAt',
    CUSTOMER = 'customer',
    DOCUMENT = 'document',
    SALE_TOTAL = 'saleTotal',
    TOTAL = 'amount',
    CASH = 'cashAmount',
    TRANS = 'transAmount',
    CREDIT_NOTE = 'creditNoteAmount',
    AMOUNT_PENDING = 'outstanding',
    ACTIONS = 'actions',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.KEY,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.CUSTOMER,
        dataIndex: ColumnsKeys.CUSTOMER,
        key: ColumnsKeys.CUSTOMER,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DOCUMENT,
        dataIndex: ColumnsKeys.DOCUMENT,
        key: ColumnsKeys.DOCUMENT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.SALE_TOTAL,
        dataIndex: ColumnsKeys.SALE_TOTAL,
        key: ColumnsKeys.SALE_TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.TOTAL,
        dataIndex: ColumnsKeys.TOTAL,
        key: ColumnsKeys.TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.CASH,
        dataIndex: ColumnsKeys.CASH,
        key: ColumnsKeys.CASH,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.TRANS,
        dataIndex: ColumnsKeys.TRANS,
        key: ColumnsKeys.TRANS,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.CREDIT_NOTE,
        dataIndex: ColumnsKeys.CREDIT_NOTE,
        key: ColumnsKeys.CREDIT_NOTE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.OUTSTANDING,
        dataIndex: ColumnsKeys.AMOUNT_PENDING,
        key: ColumnsKeys.AMOUNT_PENDING,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
];

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.TOTAL]: SummaryType.SUM,
    [ColumnsKeys.CASH]: SummaryType.SUM,
    [ColumnsKeys.TRANS]: SummaryType.SUM,
    [ColumnsKeys.CREDIT_NOTE]: SummaryType.SUM,
    [ColumnsKeys.NO]: SummaryType.COUNT,
}