import { Languages } from "src/common/descriptions/types";
import { CreateCustomerDescriptions } from "./types";

const DescriptionsEnUs: CreateCustomerDescriptions = {
    EDIT_TITLE: "Edit Customer",
    TITLE: "Create Customer",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save customer?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Customer input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Customer Created",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Customer Updated",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        SAVE_ACTION: "",
        UNKNOWN: "An error ocurred while processing request, please contact support."
    },
    FORM: {
        NAME: {
            LABEL: "Name",
            PLACEHOLDER: "Input Comercial Name",
        },
        TRADE_NAME: {
            LABEL: "Trade name",
            PLACEHOLDER: "Iinput Trade Name",
        },
        INVOICE_RECEIVER_ID: {
            LABEL: "Invoice Receiver Id",
            PLACEHOLDER: "Input NIT/CUI",
        },
        ADDRESS: {
            LABEL: "Address",
            PLACEHOLDER: "Input Address",
        },
        PHONE: {
            LABEL: "Phone",
            PLACEHOLDER: "Input Phone",
        },
        EMAIL: {
            LABEL: "Email",
            PLACEHOLDER: "Input Email",
        },
        LOCATION: {
            LABEL: "Location",
            PLACEHOLDER: "Select Location",
        },
        CREDIT_DAYS: {
            LABEL: "Credit Days",
            PLACEHOLDER: "Input Credit Days",
        },
        CREDIT_LIMIT: {
            LABEL: "Credit Limit",
            PLACEHOLDER: "Input Credit Limit",
        },
    },
    MAIN_ACTION_TITLE: "Creating Customer",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
}

const DescriptionsEsUs: CreateCustomerDescriptions = {
    EDIT_TITLE: "Editar Cliente",
    TITLE: "Crear Cliente",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Cliente con los datos ingresados?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de Cliente?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Cliente creado",
    },
    SUCCESS_SECONDARY_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Cliente Actualizado",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        SAVE_ACTION: "",
        UNKNOWN: "Ha ocurrido un error al procesar la operación, por favor contacta a soporte."
    },
    FORM: {
        NAME: {
            LABEL: "Nombre",
            PLACEHOLDER: "Ingrese Nombre Comercial",
        },
        TRADE_NAME: {
            LABEL: "Razón Social",
            PLACEHOLDER: "Ingrese Razón Social",
        },
        INVOICE_RECEIVER_ID: {
            LABEL: "NIT/CUI",
            PLACEHOLDER: "Ingrese NIT/CUI",
        },
        ADDRESS: {
            LABEL: "Dirección",
            PLACEHOLDER: "Ingrese Dirección",
        },
        PHONE: {
            LABEL: "Teléfono",
            PLACEHOLDER: "Ingrese Teléfono",
        },
        EMAIL: {
            LABEL: "Correo",
            PLACEHOLDER: "Ingrese Correo",
        },
        LOCATION: {
            LABEL: "Localidad",
            PLACEHOLDER: "Seleccione Localidad",
        },
        CREDIT_DAYS: {
            LABEL: "Días de Crédito",
            PLACEHOLDER: "Ingrese dias de crédito",
        },
        CREDIT_LIMIT: {
            LABEL: "Límite de Crédito",
            PLACEHOLDER: "Inrese límite",
        },
    },
    MAIN_ACTION_TITLE: "Creando Cliente",
    MAIN_ACTION_DESCRIPTION: "Por favor espere...",
}

export const createCustomerDictionary = {}
createCustomerDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createCustomerDictionary[Languages.SPANISH_US] = DescriptionsEsUs