import { Col, DatePicker, Form, Row, Table } from "antd/es";
import dayjs from 'dayjs';
import { DateRanges, DefaultValues, SummaryType } from "./types";
import { ReactNode, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { SystemConstants } from "src/common/constants";
import * as Styles from './styles';
import { Col4, Col5, Col6 } from "../Columns";
import { SystemDescriptions } from "src/common/descriptions";
import InnerLoader from "../InnerLoader";
import './styles.css'

const { RangePicker } = DatePicker;

type Props<T> = {
    showSearch?: boolean
    searchLabel?: string

    showRangePicker?: boolean
    rangePickerDefaultValues?: [dayjs.Dayjs, dayjs.Dayjs]
    rangePickerOnChange?: (dateRanges: DateRanges) => void

    showActionButton?: boolean
    actionButtonLabel?: string
    actionButtonOnClick?: () => void
    actionButtonDisabled?: boolean

    items: T[]
    tableColumns: ColumnsType<T>

    showLoader?: boolean

    summaryColumns?: Record<string, SummaryType>

    children?: JSX.Element
}

export const SearchableTable = <T,>(props: Props<T>) => {

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filteredItems, setFilteredItems] = useState<T[]>([])
    const [rangePickerOpen, setRangePickerOpen] = useState<boolean>(false);
    const [form] = Form.useForm();

    const descriptions = SystemDescriptions.COMPONENTS.SEARCHABLE_TABLE

    useEffect(() => {
        filterItems(props.items, searchTerm)
    }, [props.items])

    const filterItems = (items: T[], newSearchTerm: string) => {

        const filteredItems = items.filter((o: any) =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(newSearchTerm.toLowerCase())
            )
        );

        setFilteredItems(filteredItems)
    }

    const handleSearchKeyUp = (e) => {
        const newSearchTerm = e.target.value
        setSearchTerm(newSearchTerm)
        filterItems(props.items, newSearchTerm)
    }

    const handleRangeChanges = (dateRanges) => {
        if (props.rangePickerOnChange) {
            props.rangePickerOnChange({
                startDate: dateRanges[0].format(SystemConstants.DATETIME_FORMAT),
                endDate: dateRanges[1].format(SystemConstants.DATETIME_FORMAT),
            })
        }
    }

    const renderExtraFooter = () => {

        const triggerDatesChange = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
            form.setFieldsValue({
                range_picker: [startDate, endDate],
            })
            setRangePickerOpen(false)
            handleRangeChanges([startDate, endDate])
        }

        const todayClick = () => {
            triggerDatesChange(dayjs(), dayjs())
        }

        const yesterdayClick = () => {
            triggerDatesChange(dayjs().add(-1, 'days'), dayjs().add(-1, 'days'))
        }

        const thisWeekClick = () => {
            triggerDatesChange(dayjs().startOf('week'), dayjs())
        }

        const lastWeekClick = () => {
            triggerDatesChange(
                dayjs().add(-7, 'days').startOf('week'),
                dayjs().add(-7, 'days').endOf('week')
            )
        }

        const thisMonthClick = () => {
            triggerDatesChange(
                dayjs().startOf('month'),
                dayjs()
            )
        }

        const lastMonthClick = () => {
            triggerDatesChange(
                dayjs().add(-1, 'month').startOf('month'),
                dayjs().add(-1, 'month').endOf('month'),
            )
        }

        return <Row>
            <Col md={0} xs={0} sm={0} lg={24}>
                <Styles.RangePickerFooterContainer>
                    <Styles.ShortCutButon
                        size="small"
                        onClick={todayClick}>{descriptions.RANGE_PICKER.SHORTCUTS.TODAY}
                    </Styles.ShortCutButon>
                    <Styles.ShortCutButon
                        size="small"
                        onClick={yesterdayClick}>{descriptions.RANGE_PICKER.SHORTCUTS.YESTERDAY}
                    </Styles.ShortCutButon>
                    <Styles.ShortCutButon
                        size="small"
                        onClick={thisWeekClick}>{descriptions.RANGE_PICKER.SHORTCUTS.THIS_WEEK}
                    </Styles.ShortCutButon>
                    <Styles.ShortCutButon
                        size="small"
                        onClick={lastWeekClick}>{descriptions.RANGE_PICKER.SHORTCUTS.LAST_WEEK}
                    </Styles.ShortCutButon>
                    <Styles.ShortCutButon
                        size="small"
                        onClick={thisMonthClick}>{descriptions.RANGE_PICKER.SHORTCUTS.THIS_MONTH}
                    </Styles.ShortCutButon>
                    <Styles.ShortCutButon
                        size="small"
                        onClick={lastMonthClick}>{descriptions.RANGE_PICKER.SHORTCUTS.LAST_MONTH}
                    </Styles.ShortCutButon>
                </Styles.RangePickerFooterContainer>
            </Col>
        </Row>
    }

    const renderSummary = (pageData) => {
        if (!props.summaryColumns) {
            return null
        }

        const tableColumns = [...props.tableColumns]
        const summaryColumns = { ...props.summaryColumns }

        return (
            <Table.Summary.Row style={{ backgroundColor: '#F5F5F5', fontWeight: 'bold' }} >
                {
                    tableColumns.map((column, idx) => {
                        const columnKey: string = column.key as string

                        if (!summaryColumns[columnKey] || !columnKey) {
                            return <Table.Summary.Cell index={idx} key={columnKey} />
                        }

                        let value: number = 0

                        switch (summaryColumns[columnKey]) {
                            case SummaryType.AVERAGE:
                                // TODO: BUILD
                                value = 0
                                break;

                            case SummaryType.COUNT:
                                value = pageData.filter((element: T) => !!element[columnKey]).length
                                break;

                            case SummaryType.SUM:
                                pageData.forEach((element: T) => {
                                    value += parseFloat(element[columnKey])
                                });

                                break;

                            default:
                                break;
                        }

                        return <Table.Summary.Cell
                            index={idx}
                            align={column.align}
                            key={columnKey}
                        >
                            {
                                column.render
                                    ? column.render(value, {} as any, idx) as ReactNode
                                    : value
                            }
                        </Table.Summary.Cell>
                    })
                }
            </Table.Summary.Row>
        )
    }

    return (
        <>
            <Row gutter={[12, 0]} style={{ marginBottom: '0px' }} >
                {
                    props.showSearch &&
                    <Col6>
                        <Form.Item>
                            <Styles.SearchInput
                                placeholder={props.searchLabel || DefaultValues.SEARCH_LABEL}
                                onKeyUp={handleSearchKeyUp}
                            />
                        </Form.Item>
                    </Col6>
                }
                <Col
                    flex={1}
                    style={{ marginBottom: props.children ? 20 : 0 }} >
                    {props.children}
                </Col>
                {
                    props.showRangePicker &&
                    <Col5>
                        <Form
                            form={form}
                            initialValues={{
                                range_picker: props.rangePickerDefaultValues,
                            }}
                            style={{ width: '100%', }}
                        >
                            <Form.Item
                                name="range_picker"
                                rules={[{ required: true, message: 'Please select date' }]}
                            >
                                <RangePicker
                                    popupClassName="responsive-calendar"
                                    onChange={handleRangeChanges}
                                    format={SystemConstants.DATE_FORMAT}
                                    disabledDate={(current) => {
                                        let customDate = dayjs()
                                        return current && current > customDate
                                    }}
                                    renderExtraFooter={renderExtraFooter}
                                    disabled={props.showLoader}
                                    getPopupContainer={trigger => trigger.parentElement!}
                                    style={{ width: '100%' }}
                                    open={rangePickerOpen}
                                    onOpenChange={(open) => setRangePickerOpen(open)}
                                />
                            </Form.Item>
                        </Form>
                    </Col5>
                }

                {
                    props.showActionButton &&
                    <Col4>
                        <Form.Item>
                            <Styles.ActionButton
                                type="primary"
                                style={{ width: "100%" }}
                                onClick={props.actionButtonOnClick}
                                disabled={props.actionButtonDisabled || props.showLoader}
                            >
                                {props.actionButtonLabel || DefaultValues.ACTION_BUTTON_LABEL}
                            </Styles.ActionButton>
                        </Form.Item>
                    </Col4>
                }
            </Row>

            <Styles.TableContainer>
                <Table
                    dataSource={filteredItems as any[]}
                    columns={props.tableColumns}
                    size="small"
                    pagination={{ pageSize: 50 }}
                    summary={renderSummary}
                    rowClassName={() => 'editable-row'}
                    loading={{
                        indicator: (
                            <>
                                <Styles.LoaderContainer>
                                    <InnerLoader />
                                    <Styles.LoaderLabel>
                                        {descriptions.TABLE_LOADER}
                                    </Styles.LoaderLabel>
                                </Styles.LoaderContainer>
                            </>
                        ),
                        spinning: props.showLoader,
                    }}
                />
            </Styles.TableContainer>
        </>
    )

}
