import { Action } from 'redux'
import * as Actions from './actionTypes'
import { Customer } from 'src/common/models/customer'

export interface getCustomersAction extends Action {
}

export interface getCustomersSuccessAction extends Action {
    customers: Customer[]
}

export interface ErrorAction extends Action {
    error?
}

export const getCustomersApi = (): getCustomersAction => ({
    type: Actions.GET_CUSTOMERS_API
})

export const getCustomersApiSuccess = (customers: any): getCustomersSuccessAction => ({
    type: Actions.GET_CUSTOMERS_API_SUCCESS,
    customers,
})

export const getCustomersApiError = (error?): ErrorAction => ({
    type: Actions.GET_CUSTOMERS_API_ERROR,
    error,
})

export interface UpsertCustomerAction extends Action {
    customer: Customer
}
export interface UpsertCustomerSuccessAction extends Action {
    customer: Customer
}
export const upsertCustomerApi = (customer: Customer): UpsertCustomerAction => ({
    type: Actions.UPSERT_CUSTOMER_API,
    customer,
})
export const upsertCustomerApiSuccess = (customer: Customer): UpsertCustomerSuccessAction => ({
    type: Actions.UPSERT_CUSTOMER_API_SUCCESS,
    customer,
})
export const upsertCustomerError = (error?): ErrorAction => ({
    type: Actions.UPSERT_CUSTOMER_API_ERROR,
    error,
})

export interface GetCustomerByIdAction extends Action {
    id: number
}
export interface GetCustomerByIdSuccessAction extends Action {
    customer: Customer
}
export const getCustomerByIdApi = (id: number): GetCustomerByIdAction => ({
    type: Actions.GET_CUSTOMER_BY_ID_API,
    id,
})
export const getCustomerByIdApiSuccess = (customer: Customer): GetCustomerByIdSuccessAction => ({
    type: Actions.GET_CUSTOMER_BY_ID_API_SUCCESS,
    customer,
})
export const getCustomerByIdError = (error?): ErrorAction => ({
    type: Actions.GET_CUSTOMER_BY_ID_API_ERROR,
    error,
})