export interface AppTheme {
    THEME: {
        BUTTON_PRIMARY: string
    }
    LOGIN: {
        BACKGROUND: string
    }
    SIDEBAR: {
        SELECTED_ITEM: string
        BACKGROUND: string
        SELECTED_ITEM_COLOR: string
    }
}

export enum AppThemeNames {
    DEFAULT = 'DEFAULT',
    CRIMSON = 'CRIMSON'
}