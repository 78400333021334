import { AppTheme } from "./types";

export const CrimsonTheme: AppTheme = {
    THEME: {
        BUTTON_PRIMARY: "#A3AA9C",
    },
    LOGIN: {
        BACKGROUND: "#F7F8F2",
    },
    SIDEBAR: {
        SELECTED_ITEM: "#DA5E60",
        BACKGROUND: "#575656",
        SELECTED_ITEM_COLOR: "#FFF",
    },
}