import { FlexDiv } from "src/common/styles/div";
import styled from "styled-components";

export const Container = styled(FlexDiv)`
`
export const Title = styled.span`
    font-size: 20px;
    font-weight: bold;
    font-family: Quicksand;
`
export const Description = styled.span`
    font-size: 14px;
    font-family: Quicksand;
`