import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency } from "src/common/util";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.REPORTS.UNITS_SOLD

export enum ColumnsKeys {
    KEY = 'key',
    PRODUCT_ID = 'productId',
    MEASSURE = 'meassure',
    DESCRIPTION = 'product',
    QTY = 'qty',
    AVERAGE_PRICE = 'averagePrice',
    AVERAGE_COSTE = 'averageCoste',
    BRAND = 'productBrand',
    SUB_BRAND = 'productSubbrand',
    CATEGORY = 'productCategory',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.PRODUCT_ID,
        dataIndex: ColumnsKeys.PRODUCT_ID,
        key: ColumnsKeys.PRODUCT_ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DESCRIPTION,
        dataIndex: ColumnsKeys.DESCRIPTION,
        key: ColumnsKeys.DESCRIPTION,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.BRAND,
        dataIndex: ColumnsKeys.BRAND,
        key: ColumnsKeys.BRAND,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.SUB_BRAND,
        dataIndex: ColumnsKeys.SUB_BRAND,
        key: ColumnsKeys.SUB_BRAND,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.CATEGORY,
        dataIndex: ColumnsKeys.CATEGORY,
        key: ColumnsKeys.CATEGORY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.QTY,
        dataIndex: ColumnsKeys.QTY,
        key: ColumnsKeys.QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.AVERAGE_PRICE,
        dataIndex: ColumnsKeys.AVERAGE_PRICE,
        key: ColumnsKeys.AVERAGE_PRICE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.AVERAGE_COSTE,
        dataIndex: ColumnsKeys.AVERAGE_COSTE,
        key: ColumnsKeys.AVERAGE_COSTE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
];