import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetSaleRecordsFilters, Sale, SalesReport } from 'src/common/models/sale'
import { ReportView } from 'src/common/models/report'
import { TransactionPayment } from 'src/common/models/transactionPayment'
import { GetSalePaymentRecordsFilters, SalePayment } from 'src/common/models/salePayment'

export interface CreateSaleAction extends Action {
    sale: Sale
}
export interface CreateSaleSuccessAction extends Action {
    sale: Sale
}
export interface ErrorAction extends Action {
    error?
}
export const createSaleApi = (sale: Sale): CreateSaleAction => ({
    type: Actions.CREATE_SALE_API,
    sale,
})
export const createSaleSuccess = (sale: Sale): CreateSaleSuccessAction => ({
    type: Actions.CREATE_SALE_API_SUCCESS,
    sale,
})
export const createSaleError = (error?): ErrorAction => ({
    type: Actions.CREATE_SALE_API_ERROR,
    error,
})

export interface GetSaleRecordsAction extends Action {
    filters?: GetSaleRecordsFilters
}
export interface GetSaleRecordsSuccessAction extends Action {
    sales: Sale[]
}
export const getSaleRecordsApi = (filters?: GetSaleRecordsFilters): GetSaleRecordsAction => ({
    type: Actions.GET_SALE_RECORDS_API,
    filters,
})
export const getSaleRecordsSuccess = (sales: Sale[]): GetSaleRecordsSuccessAction => ({
    type: Actions.GET_SALE_RECORDS_API_SUCCESS,
    sales,
})
export const getSaleRecordsError = (error?): ErrorAction => ({
    type: Actions.GET_SALE_RECORDS_API_ERROR,
    error,
})

export interface GetSaleByIdAction extends Action {
    saleId: number
}
export interface GetSaleByIdSuccessAction extends Action {
    sale: Sale
}
export const getSaleByIdApi = (saleId: number): GetSaleByIdAction => ({
    type: Actions.GET_SALE_BY_ID_API,
    saleId,
})
export const getSaleByIdSuccess = (sale: Sale): GetSaleByIdSuccessAction => ({
    type: Actions.GET_SALE_BY_ID_API_SUCCESS,
    sale,
})
export const getSaleByIdError = (error?): ErrorAction => ({
    type: Actions.GET_SALE_BY_ID_API_ERROR,
    error,
})

export const clearCurrentSale = (): Action => ({
    type: Actions.CLEAR_CURRENT_SALE,
})

export interface CertifySaleAction extends Action {
    sale: Sale
}
export interface CertifySaleSuccessAction extends Action {
    sale: Sale
}
export const certifySaleApi = (sale: Sale): CertifySaleAction => ({
    type: Actions.CERTIFY_SALE_API,
    sale,
})
export const certifySaleSuccess = (sale: Sale): CertifySaleSuccessAction => ({
    type: Actions.CERTIFY_SALE_API_SUCCESS,
    sale,
})
export const certifySaleError = (error?): ErrorAction => ({
    type: Actions.CERTIFY_SALE_API_ERROR,
    error,
})

export interface GetSalesReportAction extends Action {
    filters?: GetSaleRecordsFilters
}
export interface GetSalesReportSuccessAction extends Action {
    report: SalesReport
    view: ReportView
}
export const getSalesReportApi = (filters: GetSaleRecordsFilters): GetSalesReportAction => ({
    type: Actions.GET_SALES_REPORT_API,
    filters,
})
export const getSalesReportSuccess = (report: SalesReport, view: ReportView): GetSalesReportSuccessAction => ({
    type: Actions.GET_SALES_REPORT_API_SUCCESS,
    report,
    view,
})
export const getSalesReportError = (error?): ErrorAction => ({
    type: Actions.GET_SALES_REPORT_API_ERROR,
    error,
})

export interface CreateSalePaymentAction extends Action {
    paymentData: TransactionPayment
}
export interface CreateSalePaymentSuccessAction extends Action {
    paymentData: TransactionPayment
}
export const createSalePaymentApi = (paymentData: TransactionPayment): CreateSalePaymentAction => ({
    type: Actions.CREATE_SALE_PAYMENT_API,
    paymentData,
})
export const createSalePaymentSuccess = (paymentData: TransactionPayment): CreateSalePaymentSuccessAction => ({
    type: Actions.CREATE_SALE_PAYMENT_API_SUCCESS,
    paymentData,
})
export const createSalePaymentError = (error?): ErrorAction => ({
    type: Actions.CREATE_SALE_PAYMENT_API_ERROR,
    error,
})

export interface GetSalePaymentRecordsAction extends Action {
    filters?: GetSalePaymentRecordsFilters
}
export interface GetSalePaymentRecordsSuccessAction extends Action {
    salePayments: SalePayment[]
}
export const getSalePaymentRecordsApi = (filters?: GetSalePaymentRecordsFilters): GetSalePaymentRecordsAction => ({
    type: Actions.GET_SALE_PAYMENTS_API,
    filters,
})
export const getSalePaymentRecordsSuccess = (salePayments: SalePayment[]): GetSalePaymentRecordsSuccessAction => ({
    type: Actions.GET_SALE_PAYMENTS_API_SUCCESS,
    salePayments,
})
export const getSalePaymentRecordsError = (error?): ErrorAction => ({
    type: Actions.GET_SALE_PAYMENTS_API_ERROR,
    error,
})

export const cleanSalePaymentRecordsApi = (): Action => ({
    type: Actions.CLEAN_SALE_PAYMENTS,
})