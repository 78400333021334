import { Languages } from "../../types";
import { ModalPaymentDescriptions } from "./types";

const DescriptionsEnUs: ModalPaymentDescriptions = {
    TITLE_PURCHASE: "Create Purchase Payment",
    TITLE_SALE: "Create Sale Payment",
    DATA: {
        TARGET_PURCHASE: "Supplier",
        TARGET_SALE: "Customer",
        TOTAL_PURCHASE: "Purchase Total",
        TOTAL_SALE: "Sale Total",
        CURRENT_PAYED: "Current Payed",
        CURRENT_BALANCE: "Current Balance",
    },
    FORM: {
        CASH: {
            AMOUNT: {
                LABEL: "Amount",
                PLACEHOLDER: "0",
            },
            DATE: {
                LABEL: "Date",
                PLACEHOLDER: "Date",
            },
            REFERENCE: {
                LABEL: "Reference",
                PLACEHOLDER: "Input reference",
            },
        },
        TRANSACTION: {
            AMOUNT: {
                LABEL: "Amount",
                PLACEHOLDER: "Amount",
            },
            DATE: {
                LABEL: "Date",
                PLACEHOLDER: "Date",
            },
            REFERENCE: {
                LABEL: "Reference",
                PLACEHOLDER: "Input Reference",
            },
            BANK: {
                LABEL: "Bank",
                PLACEHOLDER: "Input Bank",
            },
        },
        CREDIT_NOTE: {
            AMOUNT: {
                LABEL: "Amount",
                PLACEHOLDER: "Amount",
            },
            DATE: {
                LABEL: "Date",
                PLACEHOLDER: "Date",
            },
            REFERENCE: {
                LABEL: "Reference",
                PLACEHOLDER: "Reference",
            },
        },
        PAYMENT_AMOUNT: {
            LABEL: "Payment Amount",
            PLACEHOLDER: "0",
        },
        NEW_BALANCE: {
            LABEL: "New Balance",
            PLACEHOLDER: "0",
        },
    },
    ERRORS: {
        INVALID_QTY: "Invalid quantity",
        PAYMENTS_MUST_BE_GREATER: "Payment Amount must be lower or equal to current balance.",
        PAYMENT_MUST_BE_NOT_ZERO: "Payent Amount must be greater than zero.",
        PAYMENT_MUST_BE_LOWER_EQUAL_BALANCE: "Payment amount must be lower or equal to current balance, this will be stored as an abonation.",
    },
    ACCEPT: "Save",
    CANCEL: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Create Payment?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Payment input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Payment Created",
    },
    TABS: {
        CASH: "Cash",
        TRANSACTION: "Transaction",
        CREDIT_NOTE: "Credit Note",
    },
    SHORTCUTS: {
        CASH_COMPLETE: "Complete",
        CASH_TODAY: "Today",
        CASH_NO_REF: "NA",
        CASH_AUTO: "Full Cash",
    },
}

const DescriptionsEsUs: ModalPaymentDescriptions = {
    TITLE_PURCHASE: "Crear Pago de Compra",
    TITLE_SALE: "Crear Pago de Venta",
    DATA: {
        TARGET_PURCHASE: "Proveedor",
        TARGET_SALE: "Cliente",
        TOTAL_PURCHASE: "Total Compra",
        TOTAL_SALE: "Total Venta",
        CURRENT_PAYED: "Monto Pagado",
        CURRENT_BALANCE: "Saldo Actual",
    },
    FORM: {
        CASH: {
            AMOUNT: {
                LABEL: "Monto",
                PLACEHOLDER: "0",
            },
            DATE: {
                LABEL: "Fecha",
                PLACEHOLDER: "Fecha",
            },
            REFERENCE: {
                LABEL: "Referencia",
                PLACEHOLDER: "Ingrese referencia",
            },
        },
        TRANSACTION: {
            AMOUNT: {
                LABEL: "Monto",
                PLACEHOLDER: "0",
            },
            DATE: {
                LABEL: "Fecha",
                PLACEHOLDER: "Fecha",
            },
            REFERENCE: {
                LABEL: "Referencia",
                PLACEHOLDER: "Ingrese Referencia",
            },
            BANK: {
                LABEL: "Banco",
                PLACEHOLDER: "Ingrese Banco",
            },
        },
        CREDIT_NOTE: {
            AMOUNT: {
                LABEL: "Monto",
                PLACEHOLDER: "0",
            },
            DATE: {
                LABEL: "Fecha",
                PLACEHOLDER: "Fecha",
            },
            REFERENCE: {
                LABEL: "Referencia",
                PLACEHOLDER: "Ingrese referencia",
            },
        },
        PAYMENT_AMOUNT: {
            LABEL: "Total Pago",
            PLACEHOLDER: "0",
        },
        NEW_BALANCE: {
            LABEL: "Nuevo Saldo",
            PLACEHOLDER: "0",
        },
    },
    ERRORS: {
        INVALID_QTY: "Cantidad inválida",
        PAYMENTS_MUST_BE_GREATER: "El monto a pagar debe ser menor o igual al saldo actual",
        PAYMENT_MUST_BE_NOT_ZERO: "El monto a pagar debe ser mayor a cero",
        PAYMENT_MUST_BE_LOWER_EQUAL_BALANCE: "El monto a pagar debe ser menor o igual al saldo actual",
    },
    ACCEPT: "Guardar",
    CANCEL: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Pago con los datos ingresados?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar ingreso de Pago?",
        DESCRIPTION: "La información ingresada sera borrada.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir ingresando",
    },
    SUCCESS_MODAL: {
        TITLE: "Exito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Pago creado",
    },
    TABS: {
        CASH: "Efectivo",
        TRANSACTION: "Transacción",
        CREDIT_NOTE: "Nota de crédito",
    },
    SHORTCUTS: {
        CASH_COMPLETE: "Completo",
        CASH_TODAY: "Hoy",
        CASH_NO_REF: "NA",
        CASH_AUTO: "Efectivo Total",
    },
}

const productTransactionDictionary = {}
productTransactionDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
productTransactionDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getPaymentModalDescriptions = (systemLang: Languages): ModalPaymentDescriptions => (
    productTransactionDictionary[systemLang]
)