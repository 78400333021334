import { Languages } from "src/common/descriptions/types";
import { AccountReceivablesDescriptions } from "./types";

const DescriptionsEnUs: AccountReceivablesDescriptions = {
    TITLE: "Account Receivables",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Datetime",
        CUSTOMER: "Customer",
        DOCUMENT: "Document",
        TOTAL: "Total",
        OUTSTANDING: "Outstanding",
        EXPIRATION: "Expiration",
        ELLAPSED: "Ellapsed",
        STATUS: "Status",
        ACTIONS: "Actions",
    },
    ACTIONS: {
        VIEW_DETAIL: "View Detail",
        CREATE_PAYMENT: "Create Payment",
        PAYMENT_RECORDS: "Payment Records",
    },
    STATUS_PENDING: "Pending",
    STATUS_PAYED: "Payed",
    EXPORT: "Export Data",
}

const DescriptionsEsUs: AccountReceivablesDescriptions = {
    TITLE: "Cuentas por cobrar",
    TABLE: {
        ACTION_BUTTON: "Ver Registros",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando datos",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Fecha",
        CUSTOMER: "Customer",
        DOCUMENT: "Documento",
        TOTAL: "Total",
        OUTSTANDING: "Saldo",
        EXPIRATION: "Vencimiento",
        ELLAPSED: "Transcurrido",
        STATUS: "Estado",
        ACTIONS: "Acciones",
    },
    ACTIONS: {
        VIEW_DETAIL: "Ver Detalle",
        CREATE_PAYMENT: "Crear Pago",
        PAYMENT_RECORDS: "Historial de Pagos",
    },
    STATUS_PENDING: "Pendiente",
    STATUS_PAYED: "Pagada",
    EXPORT: "Exportar Datos",
}

export const accountReceivablesDictionary = {}
accountReceivablesDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
accountReceivablesDictionary[Languages.SPANISH_US] = DescriptionsEsUs