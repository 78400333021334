import { Button } from "antd";
import { HorizontalFlexDiv } from "src/common/styles/div";
import styled from "styled-components";
import Search from "antd/es/input/Search"
import { Colors } from "src/common/styles/colors";
import { Fonts } from "src/common/styles/fonts";
import { AppTheme } from "src/common/themes/types";
import { getAppTheme } from "src/common/util";

const appTheme: AppTheme = getAppTheme()

export const RangePickerFooterContainer = styled(HorizontalFlexDiv)`
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    gap: 8px;
    justify-content: space-between;
`
export const ShortCutButon = styled(Button)`
    font-size: 12px;
`
export const TableContainer = styled.div`
    overflow: auto;
`
export const SearchInput = styled(Search)`
    width: 100%;
`
export const LoaderContainer = styled(HorizontalFlexDiv)`
    justify-content: center;
    margin-top: 70px;
    transform: rotate(0deg);
`
export const LoaderLabel = styled.span`
    color: ${Colors.BLUE_ZODIAC};
    font-family: ${Fonts.INTER};
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.82;
    letter-spacing: 1px;
    margin: 6px 0 0 8px;
    text-align: left;
`
export const ActionButton = styled(Button)`
    background-color: ${appTheme.THEME.BUTTON_PRIMARY};
    color: white;

    &:hover {
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.10);
        background-color: ${appTheme.THEME.BUTTON_PRIMARY} !important;
        color: white !important;
  }
`