import { PageCard } from "src/common/styles/styles"
import { CreateSaleForm } from "./CreateSaleForm"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { useEffect, useState } from "react"
import { Sale } from "src/common/models/sale"
import { ModalSuccess } from "src/components/ModalSuccess"
import { SaleError } from "../state/types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_SALE_API } from "../state/actionTypes"
import { createSaleApi } from "../state/action"
import { Urls } from "src/common/urls"
import { useNavigate } from "react-router"
import { Loader } from "src/components/Loader"
import { SystemConstants } from "src/common/constants"
import { SystemDescriptions } from "src/common/descriptions"
import { clearInvoiceReceiver } from "src/features/Dte/state/action"
import { removeSessionData } from "src/session/sessionStore"
import { SessionDataField } from "src/session/types"
import { getDteDocument } from "src/common/util"

interface ReduxProps {
    error?: SaleError
    createSaleSuccess: boolean
    isCreatingSale: boolean
    upsertedSale?: Sale
    reCertification?: boolean
}

export const CreateSale = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [formValues, setFormValues] = useState<Sale | undefined>()
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const descriptions = SystemDescriptions.PAGES.SALE.CREATE

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.sale.error,
        createSaleSuccess: state.sale.createSaleSuccess,
        isCreatingSale: serviceLoading(state, [CREATE_SALE_API]),
        upsertedSale: state.sale.upsertedSale,
    }))

    useEffect(() => {
        if (reduxProps.createSaleSuccess) {
            removeSessionData(SessionDataField.CURRENT_SALE)
        }
    }, [reduxProps.createSaleSuccess])

    const onFinish = (sale: Partial<any>) => {
        setFormValues(sale as Sale)
        setModalConfirmationOpen(true)
    }

    const renderForm = () => (
        <CreateSaleForm
            onCancel={() => {
                setModalCancelOpen(true)
            }}
            onFinish={onFinish}
            reCertification={true}
        />
    )

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)

        if (formValues?.id) {
            console.log("UNDER CONSTRUCTION")
        } else {
            dispatch(createSaleApi(formValues as Sale))
        }
    }

    const handleCancelAccept = () => {
        dispatch(clearInvoiceReceiver())
        navigate(Urls.FRONTEND.SALE.RECORDS)
    }

    const handlePrintSale = () => {
        if (reduxProps.upsertedSale && reduxProps.upsertedSale.certificationData?.uuid) {
            getDteDocument(reduxProps.upsertedSale.certificationData?.uuid)
        }
    }

    const buildSuccessModalDescription = (): string => {
        let successMessage: string = descriptions.SUCCESS_MODAL.DESCRIPTION

        if (SystemConstants.INVOICE_FEATURE_FLAG) {
            if (!reduxProps.upsertedSale?.certificationData) {
                successMessage += `\n${descriptions.FORM.ERRORS.DTE_NOT_CERTIFIED}`
            }
        }

        return successMessage
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setModalConfirmationOpen(false)
                }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CONFIRM_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={(reduxProps.createSaleSuccess) && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={buildSuccessModalDescription()}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handlePrintSale}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={!reduxProps.upsertedSale?.certificationData}
                successWithWarning={
                    SystemConstants.INVOICE_FEATURE_FLAG
                        ? !reduxProps.upsertedSale?.certificationData
                        : false
                }
            />
        </>
    )

    const renderLoader = () => (
        <Loader
            isVisible={reduxProps.isCreatingSale}
            title={SystemConstants.INVOICE_FEATURE_FLAG ? descriptions.MAIN_ACTION_TITLE : descriptions.MAIN_ACTION_TITLE_NO_INVOICE}
            description={descriptions.MAIN_ACTION_DESCRIPTION}
        />
    )

    return (
        <>
            <PageCard size="small">
                {renderForm()}
            </PageCard>
            {renderModals()}
            {renderLoader()}
        </>
    )
}
