import { Alert, Form, Input, Row } from "antd"
import { SystemDescriptions } from "src/common/descriptions";
import { Customer } from "src/common/models/customer";
import { Col8 } from "src/components/Columns";
import { FormActionButtons } from "src/components/FormActionButtons";
import { CustomerError } from "../../state/types";
import { useSelector } from "react-redux";
import { RootState } from "src/state/reducer";
import { User } from "src/common/models/user";
import { useEffect } from "react";

interface ReduxProps {
    error?: CustomerError
    user?: User
}

interface Props {
    initialValues?: Customer
    onFinish: (product: Partial<Customer>) => void
    onCancel: () => void
}

export const CreateCustomerForm = (props: Props) => {

    const [form] = Form.useForm();

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.customer.error,
        user: state.security.user,
    }))

    useEffect(() => {
        if (!props.initialValues) {
            return
        }
        const currentCustomer = props.initialValues

        form.setFieldsValue({
            name: currentCustomer.clientName,
            tradeName: currentCustomer.tradeName,
            invoiceReceiverId: currentCustomer.nit,
            address: currentCustomer.address,
            phone: currentCustomer.phone,
            email: currentCustomer.email,
            creditDays: currentCustomer.creditDays,
            creditLimit: currentCustomer.creditLimit,
        })

        // Get the current fields' values and errors
        const fields = form.getFieldsValue(true);

        // Mark fields as touched
        Object.keys(fields).forEach(field => {
            form.setFields([{
                name: field,
                touched: true,
            }]);
        });
    }, [props.initialValues])

    const descriptions = SystemDescriptions.PAGES.CUSTOMER.CREATE
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const onFinish = (formValues) => {
        const customer: Customer = {
            clientName: formValues.name,
            tradeName: formValues.tradeName || '',
            flagTradeName: false,
            address: formValues.address,
            nit: formValues.invoiceReceiverId || '',
            email: formValues.email || '',
            phone: formValues.phone,
            locationId: 1,
            statusId: 1,
            creditDays: formValues.creditDays ? Number(formValues.creditDays) : 0,
            creditLimit: formValues.creditLimit ? Number(formValues.creditLimit) : 0,
            createdBy: reduxProps.user?.id.toString() ?? '',
            createdDate: "",
        }

        if (props.initialValues && props.initialValues.id) {
            customer.id = props.initialValues.id
        }
        props.onFinish(customer)
    }

    const buildRequiredMessageErr = (fieldName: string): string => "Required"

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(['name', 'address', 'phone']) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
    }

    const handleCancelClick = () => {
        props.onCancel()
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            actionDisabled={getButtonStatus}
            onCancel={handleCancelClick}
        />
    )

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            onFinish={onFinish}
        >
            <Row gutter={[16, 8]}>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.NAME.LABEL}
                        name={"name"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.NAME.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.NAME.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.TRADE_NAME.LABEL}
                        name={"tradeName"}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.TRADE_NAME.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.INVOICE_RECEIVER_ID.LABEL}
                        name={"invoiceReceiverId"}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.INVOICE_RECEIVER_ID.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.ADDRESS.LABEL}
                        name={"address"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.ADDRESS.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.ADDRESS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.PHONE.LABEL}
                        name={"phone"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.PHONE.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.PHONE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.EMAIL.LABEL}
                        name={"email"}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.EMAIL.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                {/* <Col8>
                    <Form.Item
                        label={descriptions.FORM.LOCATION.LABEL}
                        name={"location"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.LOCATION.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.LOCATION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8> */}
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.CREDIT_DAYS.LABEL}
                        name={"creditDays"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.CREDIT_DAYS.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.CREDIT_DAYS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.FORM.CREDIT_LIMIT.LABEL}
                        name={"creditLimit"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.CREDIT_LIMIT.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.CREDIT_LIMIT.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col8>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={"ERROR"}
                    showIcon
                    description={reduxProps.error?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {renderButtons()}
        </Form>
    )

    return (
        renderForm()
    )
}
