import { Languages } from "src/common/descriptions/types";
import { UnitsSoldDescriptions } from "./types";

const DescriptionsEnUs: UnitsSoldDescriptions = {
    TITLE: "Units Sold Report",
    TITLE_UTILITY: "Units Sold Utility",
    TABLE: {
        ACTION_BUTTON: "Generate Report",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        PRODUCT_ID: "Product Id",
        MEASSURE: "Meassure",
        DESCRIPTION: "Description",
        QTY: "Qty",
        AVERAGE_COSTE: "Avg Coste",
        AVERAGE_PRICE: "Avg Price",
        BRAND: "Brand",
        SUB_BRAND: "SubBrand",
        CATEGORY: "Category",
    },
    EXPORT_DATA: "Export Data",
    PRINT: "Print",
    REPORT: {
        DATE_FROM: "From",
        DATE_TO: "To",
        PRINTED_AT: "Printed at",
        PAGE: "Page",
    },
}

const DescriptionsEsUs: UnitsSoldDescriptions = {
    TITLE: "Unidades vendidas",
    TITLE_UTILITY: "Utilidad Unidades Vendidas",
    TABLE: {
        ACTION_BUTTON: "Generar Reporte",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        PRODUCT_ID: "Cod Producto",
        MEASSURE: "Medida",
        DESCRIPTION: "Descripción",
        QTY: "Cant",
        AVERAGE_COSTE: "Costo medio",
        AVERAGE_PRICE: "Precio medio",
        BRAND: "Marca",
        SUB_BRAND: "SubMarca",
        CATEGORY: "Categoría",
    },
    EXPORT_DATA: "Exportar Datos",
    PRINT: "Imprimir",
    REPORT: {
        DATE_FROM: "Desde",
        DATE_TO: "Hasta",
        PRINTED_AT: "Impreso",
        PAGE: "Página",
    },
}

export const unitsSoldDictionary = {}
unitsSoldDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
unitsSoldDictionary[Languages.SPANISH_US] = DescriptionsEsUs