import styled from "styled-components";
import { Header } from "antd/es/layout/layout"
import { HorizontalFlexDiv } from "src/common/styles/div";
import { Button } from "antd";

export const HeaderApp = styled(Header)`
    padding: 0 !important;
    background-color: #fff !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`
export const SectionContainer = styled(HorizontalFlexDiv)`
`
export const NavbarItem = styled(Button)`
    font-size: 16px;
    min-width: 64px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
`
export const Title = styled.div`
    font-size: 18px;
    margin-top: 3px;
    color: #8c8c8c;
    font-family: Quicksand;
`