export const GET_CUSTOMERS_API = 'GET_CUSTOMERS_API_REQUEST'
export const GET_CUSTOMERS_API_SUCCESS = 'GET_CUSTOMERS_API_SUCCESS'
export const GET_CUSTOMERS_API_ERROR = 'GET_CUSTOMERS_API_ERROR'

export const UPSERT_CUSTOMER_API = 'UPSERT_CUSTOMER_API_REQUEST'
export const UPSERT_CUSTOMER_API_SUCCESS = 'UPSERT_CUSTOMER_API_SUCCESS'
export const UPSERT_CUSTOMER_API_ERROR = 'UPSERT_CUSTOMER_API_ERROR'

export const GET_CUSTOMER_BY_ID_API = 'GET_CUSTOMER_BY_ID_API_REQUEST'
export const GET_CUSTOMER_BY_ID_API_SUCCESS = 'GET_CUSTOMER_BY_ID_API_SUCCESS'
export const GET_CUSTOMER_BY_ID_API_ERROR = 'GET_CUSTOMER_BY_ID_API_ERROR'