import { Divider } from "antd"

interface Props {
    title: string
}

export const TitleSection = (props: Props) => {
    return (
        <Divider orientation="left" plain>
            <b>{props.title}</b>
        </Divider>
    )
}
