import { Descriptions, DescriptionsProps, List, Modal } from "antd"
import { PurchasePayment } from "src/common/models/purchasePayment"
import { SystemDescriptions } from "src/common/descriptions"
import { formatToCurrency, formatToDisplayDate } from "src/common/util"
import { SalePayment } from "src/common/models/salePayment"
import { ProductTransactionType } from "src/components/ProductTransactionModal/types"
import { TransactionPayment } from "src/common/models/transactionPayment"

interface Props {
    paymentRecords: TransactionPayment[]
    type: ProductTransactionType
    open: boolean
    onAccept: () => void
    onCancel: () => void
}

export const PaymentRecords = (props: Props) => {

    const descriptions = SystemDescriptions.PAGES.PURCHASE.PURHCASE_PAYMENT_RECORDS

    const buildListDescription = (purchasePayment): DescriptionsProps['items'] => {
        return [
            {
                key: 'CASH_AMOUNT',
                label: descriptions.CASH_AMOUNT,
                children: formatToCurrency(purchasePayment.cashAmount?.toString() || '0'),
            },
            {
                key: 'CASH_DATE',
                label: descriptions.CASH_DATE,
                children: purchasePayment.cashDate ? formatToDisplayDate(purchasePayment?.cashDate) : 'NA',
            },
            {
                key: 'CASH_REF',
                label: descriptions.CASH_REF,
                children: purchasePayment.cashRef || 'NA',
            },
            {
                key: 'TRANS_AMOUNT',
                label: descriptions.TRANS_AMOUNT,
                children: formatToCurrency(purchasePayment.transAmount?.toString() || '0'),
            },
            {
                key: 'TRANS_DATE',
                label: descriptions.TRANS_DATE,
                children: purchasePayment.transDate ? formatToDisplayDate(purchasePayment?.transDate) : 'NA',
            },
            {
                key: 'TRANS_REF',
                label: descriptions.TRANS_REF,
                children: purchasePayment.transDate
                    ? `${purchasePayment.transBank} ${purchasePayment.transRef}`
                    : 'NA',
            },
            {
                key: 'CREDIT_NOTE_AMOUNT',
                label: descriptions.CREDIT_NOTE_AMOUNT,
                children: formatToCurrency(purchasePayment.creditNoteAmount?.toString() || '0'),
            },
            {
                key: 'CREDIT_NOTE_DATE',
                label: descriptions.CREDIT_NOTE_DATE,
                children: purchasePayment.creditNoteDate ? formatToDisplayDate(purchasePayment?.creditNoteDate) : 'NA',
            },
            {
                key: 'CREDIT_NOTE_REF',
                label: descriptions.CREDIT_NOTE_REF,
                children: purchasePayment.creaditNoteRef || 'NA',
            },
        ];
    }

    const renderPaymentsList = () => {
        const data = props.type === ProductTransactionType.PURCHASE
            ? props.paymentRecords as PurchasePayment[]
            : props.paymentRecords as SalePayment[]

        return <List
            dataSource={data}
            renderItem={(item: TransactionPayment, index: number) => {
                return <List.Item>
                    <Descriptions
                        key={index}
                        title={`- ${descriptions.TOTAL}: ${formatToCurrency(item.amount.toString())}`}
                        layout="horizontal"
                        items={buildListDescription(item)}
                        size="small"
                    />
                </List.Item>
            }}
        />
    }

    return (
        <Modal
            title={descriptions.TITLE}
            centered
            open={props.open}
            onOk={props.onAccept}
            onCancel={props.onCancel}
            width={1000}
            footer={null}
            closable={true}
            maskClosable
        >
            {renderPaymentsList()}
        </Modal>
    )
}
