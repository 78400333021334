import { Button, Dropdown, MenuProps } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { SystemDescriptions } from "src/common/descriptions"
import { Sale } from "src/common/models/sale"
import { DownOutlined } from '@ant-design/icons'
import { User } from "src/common/models/user"
import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import { GET_SALE_BY_ID_API, GET_SALE_PAYMENTS_API, GET_SALE_RECORDS_API } from "../state/actionTypes"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { useEffect, useState } from "react"
import { cleanSalePaymentRecordsApi, getSaleByIdApi, getSalePaymentRecordsApi, getSaleRecordsApi } from "../state/action"
import { Loader } from "src/components/Loader"
import { SaleDetailModal } from "../SaleRecords/SaleDetailModal"
import { PaymentTransaction } from "src/components/PaymentModal/PaymentForm/types"
import { PaymentModal } from "src/components/PaymentModal"
import { ProductTransactionType } from "src/components/ProductTransactionModal/types"
import { PaymentRecords } from "src/features/Purchase/PurchaseRecords/PurchasePaymentRecords"
import { SalePayment } from "src/common/models/salePayment"

interface ReduxProps {
    isLoadingData: boolean
    accountReceivables?: Sale[]
    isGettingSale: boolean
    currentSale?: Sale
    createSalePaymentSuccess: boolean
    user?: User
    isGettingSalePayments: boolean
    salePayments?: SalePayment[]
}

export const AccountReceivables = () => {
    const dispatch = useDispatch()

    const [selectedSale, setSelectedSale] = useState<Sale | undefined>(undefined)
    const [detailButtonClicked, setDetailButtonClicked] = useState<boolean>(false)
    const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false)
    const [paymentTransaction, setPaymentTransaction] = useState<PaymentTransaction | undefined>(undefined)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SALE_RECORDS_API]),
        accountReceivables: state.sale.sales,
        isGettingSale: serviceLoading(state, [GET_SALE_BY_ID_API]),
        currentSale: state.sale.currentSale,
        createSalePaymentSuccess: state.sale.createSalePaymentSuccess,
        user: state.security.user,
        isGettingSalePayments: serviceLoading(state, [GET_SALE_PAYMENTS_API]),
        salePayments: state.sale.salePayments,
    }))

    const descriptions = SystemDescriptions.PAGES.SALE.ACCOUNT_RECEIVABLES

    const loadInitData = () => {
        dispatch(cleanSalePaymentRecordsApi())
        dispatch(getSaleRecordsApi({
            paymentStatusId: "PENDING",
        }))
    }

    useEffect(() => {
        loadInitData()
    }, [])

    useEffect(() => {
        loadInitData()
    }, [reduxProps.createSalePaymentSuccess])

    useEffect(() => {
        if (reduxProps.currentSale && detailButtonClicked) {
            setSelectedSale(reduxProps.currentSale)
        }
    }, [reduxProps.currentSale])

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Button
                size="small"
            // onClick={handleExport}
            >
                {descriptions.EXPORT}
            </Button>
        </div>
    }

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Sale) => {
                const handleDetailClick = (saleId: number) => {
                    setDetailButtonClicked(true)
                    dispatch(getSaleByIdApi(saleId))
                }

                const handleCreatePaymentClick = (_: number) => {
                    const paymentTransaction: PaymentTransaction = {
                        targetId: record.id!,
                        type: "SALE",
                        amount: record.amount,
                        amountPayed: Number(record.amount) - Number(record.outstanding),
                        currentBalance: record.outstanding,
                        target: record.shipTo || '',
                        targetDocument: record.document,
                        targetExternalId: record.customerId,
                        targetExternalName: record.shipTo || '',
                        username: reduxProps?.user?.username || '',
                        userId: 1,
                    }
                    setPaymentTransaction(paymentTransaction)
                    setPaymentModalOpen(true)
                }

                const handleViewPaymentRecordsClick = (saleId: number) => {
                    dispatch(getSalePaymentRecordsApi({
                        saleId,
                    }))
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {descriptions.ACTIONS.VIEW_DETAIL}
                            </Button>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleCreatePaymentClick(record.id!)}
                            >
                                {descriptions.ACTIONS.CREATE_PAYMENT}
                            </Button>
                        ),
                    },
                    {
                        key: '3',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleViewPaymentRecordsClick(record.id!)}
                                disabled={record.amount === record.outstanding}
                            >
                                {descriptions.ACTIONS.PAYMENT_RECORDS}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>
            },
        },
    ]

    const renderContent = () => {
        return <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.accountReceivables || []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    }

    return (
        <>
            {renderContent()}
            {
                selectedSale &&
                <SaleDetailModal
                    sale={selectedSale}
                    open={true}
                    onAccept={() => { setSelectedSale(undefined) }}
                    onCancel={() => { setSelectedSale(undefined) }}
                />
            }
            <Loader
                isVisible={reduxProps.isGettingSale || reduxProps.isGettingSalePayments}
            />
            {
                paymentTransaction &&
                <PaymentModal
                    open={paymentModalOpen}
                    type={ProductTransactionType.SALE}
                    onAccept={() => { setPaymentModalOpen(false) }}
                    onCancel={() => { setPaymentModalOpen(false) }}
                    data={paymentTransaction}
                />
            }
            <PaymentRecords
                paymentRecords={reduxProps.salePayments || []}
                open={!!reduxProps.salePayments}
                onAccept={() => { dispatch(cleanSalePaymentRecordsApi()) }}
                onCancel={() => { dispatch(cleanSalePaymentRecordsApi()) }}
                type={ProductTransactionType.SALE}
            />
        </>
    )
}
