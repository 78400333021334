import { useDispatch, useSelector } from "react-redux"
import { Sale } from "src/common/models/sale"
import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import moment from "moment"
import dayjs from 'dayjs'
import { useEffect, useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import { SystemConstants } from "src/common/constants"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_SALE_BY_ID_API, GET_SALE_PAYMENTS_API, GET_SALE_RECORDS_API } from "../state/actionTypes"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { cleanSalePaymentRecordsApi, clearCurrentSale, getSaleByIdApi, getSalePaymentRecordsApi, getSaleRecordsApi } from "../state/action"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { Button, Dropdown, MenuProps } from "antd"
import { SaleDetailModal } from "./SaleDetailModal"
import { DownOutlined, PrinterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { SystemDescriptions } from "src/common/descriptions"
import { Loader } from "src/components/Loader"
import { ReCertificationDTE } from "./ReCertificationDTE"
import { formatToDisplayDate, getDteDocument } from "src/common/util"
import { XlsExporter } from "src/common/XlsExporter"
import { Company } from "src/common/models/company"
import { ReportView } from "src/features/Reports/types"
import SalesRecordsReport from "./SaleRecordsReport"
import { PaymentTransaction } from "src/components/PaymentModal/PaymentForm/types"
import { User } from "src/common/models/user"
import { PaymentModal } from "src/components/PaymentModal"
import { ProductTransactionType } from "src/components/ProductTransactionModal/types"
import { PaymentRecords } from "src/features/Purchase/PurchaseRecords/PurchasePaymentRecords"
import { SalePayment } from "src/common/models/salePayment"

interface ReduxProps {
    isLoadingData: boolean
    saleRecords?: Sale[]
    isGettingSaleById: boolean
    currentSale?: Sale
    company?: Company
    user?: User
    createSalePaymentSuccess: boolean
    isGettingSalePayments: boolean
    salePayments?: SalePayment[]
}

export const SaleRecords = () => {

    const dispatch = useDispatch()

    const [selectedAction, setSelectedAction] = useState<"DETAIL" | "RE_CERTIFY" | undefined>(undefined)
    const [paymentTransaction, setPaymentTransaction] = useState<PaymentTransaction | undefined>(undefined)

    const initialize = () => {
        dispatch(clearCurrentSale())
        dispatch(getSaleRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    useEffect(() => {
        initialize()
    }, [])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SALE_RECORDS_API]),
        saleRecords: state.sale.sales,
        isGettingSaleById: serviceLoading(state, [GET_SALE_BY_ID_API]),
        currentSale: state.sale.currentSale,
        company: state.common.company,
        user: state.security.user,
        createSalePaymentSuccess: state.sale.createSalePaymentSuccess,
        isGettingSalePayments: serviceLoading(state, [GET_SALE_PAYMENTS_API]),
        salePayments: state.sale.salePayments,
    }))

    useEffect(() => {
        initialize()
    }, [reduxProps.createSalePaymentSuccess])

    const descriptions = SystemDescriptions.PAGES.SALE.RECORDS

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Sale) => {
                const handleDetailClick = (saleId: number) => {
                    setSelectedAction("DETAIL")
                    dispatch(getSaleByIdApi(saleId))
                }

                const handleReCertifyClick = (saleId: number) => {
                    setSelectedAction("RE_CERTIFY")
                    dispatch(getSaleByIdApi(saleId))
                }

                const handleGetDteClick = (authId: string | null) => {
                    if (authId) {
                        getDteDocument(authId)
                    }
                }

                const handleCreatePaymentClick = (_: number) => {
                    const paymentTransaction: PaymentTransaction = {
                        targetId: record.id!,
                        type: "SALE",
                        amount: record.amount,
                        amountPayed: Number(record.amount) - Number(record.outstanding),
                        currentBalance: record.outstanding,
                        target: record.shipTo || '',
                        targetDocument: record.document,
                        targetExternalId: record.customerId,
                        targetExternalName: record.shipTo || '',
                        username: reduxProps?.user?.username || '',
                        userId: 1,
                    }
                    setPaymentTransaction(paymentTransaction)
                }

                const handleViewPaymentRecordsClick = (saleId: number) => {
                    dispatch(getSalePaymentRecordsApi({
                        saleId,
                    }))
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {descriptions.ACTIONS.VIEW_DETAIL}
                            </Button>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleGetDteClick(record.authorization)}
                                disabled={!record.isDteCertified}
                            >
                                {descriptions.ACTIONS.GET_DTE}
                            </Button>
                        ),
                    },
                    {
                        key: '3',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleCreatePaymentClick(record.id!)}
                                disabled={Number(record.outstanding) === 0}
                            >
                                {descriptions.ACTIONS.CREATE_PAYMENT}
                            </Button>
                        ),
                    },
                    {
                        key: '4',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleViewPaymentRecordsClick(record.id!)}
                                disabled={record.amount === record.outstanding}
                            >
                                {descriptions.ACTIONS.PAYMENT_RECORDS}
                            </Button>
                        ),
                    },
                    {
                        key: '5',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleReCertifyClick(record.id!)}
                                disabled={record.isDteCertified}
                            >
                                {descriptions.ACTIONS.CERTIFY_DTE}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>
            },
        },
    ]

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const handleDateRangesChange = (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const handleActionButtonClick = () => {
        dispatch(getSaleRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const buildReportData = () => {
        return reduxProps?.saleRecords?.map((reportItem: Sale) => {
            return {
                No: reportItem.id,
                Fecha: formatToDisplayDate(reportItem.datetime),
                NIT: reportItem.invoiceReceiverId,
                Nombre: reportItem.invoiceReceiverName,
                Documento: reportItem.document,
                DTE: reportItem?.authorization || "NA",
                Total: reportItem.amount,
            }
        }) ?? []
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "EXPORT_EXCEL") {
            const exporter = new XlsExporter({
                sheetPrefixName: "Report",
                documentName: "SalesReport",
                appendTimestamp: true,
            })

            exporter.exportData(buildReportData())
        }
    };

    const items: MenuProps['items'] = [
        {
            label: descriptions.EXPORT_DATA,
            key: 'EXPORT_EXCEL',
            icon: <FileExcelOutlined rev={undefined} />,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!reduxProps.saleRecords || !reduxProps.company) {
            return
        }

        SalesRecordsReport({
            data: reduxProps.saleRecords,
            view: ReportView.DAY,
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
            company: reduxProps.company,
        })
    };

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Dropdown.Button
                size="small"
                menu={menuProps}
                onClick={handleButtonClick}
                disabled={!reduxProps.saleRecords || reduxProps.saleRecords.length === 0}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }} >
                    {<PrinterOutlined rev={undefined} />} {descriptions.PRINT}
                </div>
            </Dropdown.Button>
        </div>
    }

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.saleRecords ?? []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs(), dayjs()]}
                actionButtonOnClick={handleActionButtonClick}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    )

    return (
        <>
            {renderContent()}
            {
                reduxProps.currentSale && selectedAction === "DETAIL" &&
                <SaleDetailModal
                    sale={reduxProps.currentSale}
                    open={!!reduxProps.currentSale}
                    onAccept={() => { dispatch(clearCurrentSale()); }}
                    onCancel={() => { dispatch(clearCurrentSale()); }}
                />
            }
            {
                reduxProps.currentSale && selectedAction === "RE_CERTIFY" &&
                <ReCertificationDTE
                    sale={reduxProps.currentSale}
                    open={!!reduxProps.currentSale}
                    onAccept={() => {
                        dispatch(clearCurrentSale());
                        dispatch(getSaleRecordsApi({
                            dateFrom: dateRanges.startDate,
                            dateTo: dateRanges.endDate
                        }))
                    }}
                    onCancel={() => { dispatch(clearCurrentSale()); }}
                />
            }
            {
                paymentTransaction &&
                <PaymentModal
                    open={!!paymentTransaction}
                    type={ProductTransactionType.SALE}
                    onAccept={() => { setPaymentTransaction(undefined) }}
                    onCancel={() => { setPaymentTransaction(undefined) }}
                    data={paymentTransaction}
                />
            }
            <PaymentRecords
                paymentRecords={reduxProps.salePayments || []}
                open={!!reduxProps.salePayments}
                onAccept={() => { dispatch(cleanSalePaymentRecordsApi()) }}
                onCancel={() => { dispatch(cleanSalePaymentRecordsApi()) }}
                type={ProductTransactionType.SALE}
            />
            <Loader
                isVisible={reduxProps.isGettingSaleById || reduxProps.isGettingSalePayments}
            />
        </>
    )
}
