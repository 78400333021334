import { PageCard } from "src/common/styles/styles"
import { CustomerError } from "../state/types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import InnerLoader from "src/components/InnerLoader"
import { CreateCustomerForm } from "./CreateCustomerForm"
import { Loader } from "src/components/Loader"
import { Customer } from "src/common/models/customer"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { ModalSuccess } from "src/components/ModalSuccess"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { Urls } from "src/common/urls"
import { getCustomerByIdApi, upsertCustomerApi } from "../state/action"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_CUSTOMER_BY_ID_API, UPSERT_CUSTOMER_API } from "../state/actionTypes"

interface ReduxProps {
    error?: CustomerError
    isGettingCustomer: boolean
    isUpsertingCustomer: boolean
    upsertCustomerSuccess: boolean
    currentCustomer?: Customer
}

export const CreateCustomer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id: customerId } = useParams<{ id: string }>();

    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [formValues, setFormValues] = useState<Customer | undefined>()
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state?.customer?.error,
        isGettingCustomer: serviceLoading(state, [GET_CUSTOMER_BY_ID_API]),
        isUpsertingCustomer: serviceLoading(state, [UPSERT_CUSTOMER_API]),
        upsertCustomerSuccess: state.customer.upsertCustomerSuccess,
        currentCustomer: state.customer.currentCustomer,
    }))

    useEffect(() => {
        if (!customerId) {
            return
        }

        dispatch(getCustomerByIdApi(parseInt(customerId)))
    }, [customerId])

    const renderLoader = () => (
        (reduxProps.isUpsertingCustomer)
        && <Loader
            isVisible={true}
            title={"Creating customer"}
            description={"Please wait until process is finished..."}
        />
    )

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)
        dispatch(upsertCustomerApi(formValues as Customer))
    }

    const handleCancelAccept = () => {
        navigate(Urls.FRONTEND.CUSTOMER.CUSTOMERS)
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setFormValues(undefined)
                    setModalConfirmationOpen(false)
                }}
                title={"Confirmation"}
                okText={"Save"}
                cancelText={"Cancel"}
                description={"Description"}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={"Cancel"}
                okText={"Yes cancel"}
                cancelText={"Keep working"}
                description={"Descriptions"}
            />
            <ModalSuccess
                isVisible={(reduxProps.upsertCustomerSuccess) && actionButtonClicked}
                title={"Success"}
                description={"Description"}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={"Accept"}
                secondaryLabel={""}
                hideSecondaryButton={true}
            />
        </>
    )

    const onFinish = (customer: Partial<Customer>) => {
        setFormValues(customer as Customer)
        setModalConfirmationOpen(true)
    }

    const onCancel = () => {
        setModalCancelOpen(true)
    }

    return (
        <>
            <PageCard size="small" title={"Create Customer"}>
                {
                    reduxProps.isGettingCustomer
                        ? <InnerLoader />
                        : <CreateCustomerForm
                            onFinish={onFinish}
                            onCancel={onCancel}
                            initialValues={reduxProps.currentCustomer}
                        />
                }
            </PageCard>
            {renderModals()}
            {renderLoader()}
        </>
    )
}
