import { Languages } from "../../types";
import { LoaderDescriptions } from "./types";

const DescriptionsEnUs: LoaderDescriptions = {
    TITLE: "Loading...",
    DESCRIPTION: "Please wait",
}

const DescriptionsEsUs: LoaderDescriptions = {
    TITLE: "Cargando...",
    DESCRIPTION: "Espera por favor...",
}

const loaderDictionary = {}
loaderDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
loaderDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getLoaderDescriptions = (systemLang: Languages): LoaderDescriptions => (
    loaderDictionary[systemLang]
)