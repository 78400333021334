import { SystemConstants } from "src/common/constants"
import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import moment from "moment"
import dayjs from 'dayjs'
import { useEffect, useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import { summaryColumns, TABLE_COLUMNS } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { getSalePaymentRecordsApi } from "../state/action"
import { SalePayment } from "src/common/models/salePayment"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_SALE_PAYMENTS_API } from "../state/actionTypes"
import { SystemDescriptions } from "src/common/descriptions"

interface ReduxProps {
    isLoadingData: boolean
    salePayments?: SalePayment[]
}

export const SalePaymentRecords = () => {
    const dispatch = useDispatch()

    const descriptions = SystemDescriptions.PAGES.SALE.SALE_PAYMENTS

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SALE_PAYMENTS_API]),
        salePayments: state.sale.salePayments,
    }))

    useEffect(() => {
        dispatch(getSalePaymentRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }, [])


    const tblColumns = [
        ...TABLE_COLUMNS,
    ]

    const handleDateRangesChange = (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const handleActionButtonClick = () => {
        dispatch(getSalePaymentRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE}
        // extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.salePayments || []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs(), dayjs()]}
                actionButtonOnClick={handleActionButtonClick}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    )

    return (
        <>
            {renderContent()}
            {/* {
            reduxProps.currentSale && selectedAction === "DETAIL" &&
            <SaleDetailModal
                sale={reduxProps.currentSale}
                open={!!reduxProps.currentSale}
                onAccept={() => { dispatch(clearCurrentSale()); }}
                onCancel={() => { dispatch(clearCurrentSale()); }}
            />
        }
        {
            reduxProps.currentSale && selectedAction === "RE_CERTIFY" &&
            <ReCertificationDTE
                sale={reduxProps.currentSale}
                open={!!reduxProps.currentSale}
                onAccept={() => {
                    dispatch(clearCurrentSale());
                    dispatch(getSaleRecordsApi({
                        dateFrom: dateRanges.startDate,
                        dateTo: dateRanges.endDate
                    }))
                }}
                onCancel={() => { dispatch(clearCurrentSale()); }}
            />
        }
        <Loader
            isVisible={reduxProps.isGettingSaleById}
        /> */}
        </>
    )
}
