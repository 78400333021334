import { AppTheme } from "./types";

export const DefaultTheme: AppTheme = {
    THEME: {
        BUTTON_PRIMARY: "#007BFE",
    },
    LOGIN: {
        BACKGROUND: "#E8ECEF",
    },
    SIDEBAR: {
        SELECTED_ITEM: "#28A744",
        BACKGROUND: "#343A3E",
        SELECTED_ITEM_COLOR: "#fff",
    },
}