import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils"

const descriptions = SystemDescriptions.PAGES.PURCHASE.CREATE

export enum ColumnsKeys {
    NO = 'key',
    DESCRIPTION = 'product',
    MEASSURE = 'meassure',
    QTY = 'qty',
    PRICE = 'unitPrice',
    DISSCOUNT = 'disscount',
    TOTAL = 'total',
    ACTIONS = 'actions',
}

export const tableColumns: any = [
    {
        title: descriptions.FORM.DETAIL.COLUMNS.NO,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.FORM.DETAIL.COLUMNS.DESCRIPTION,
        dataIndex: ColumnsKeys.DESCRIPTION,
        key: ColumnsKeys.DESCRIPTION,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.FORM.DETAIL.COLUMNS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.FORM.DETAIL.COLUMNS.QTY,
        dataIndex: ColumnsKeys.QTY,
        key: ColumnsKeys.QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.FORM.DETAIL.COLUMNS.PRICE,
        dataIndex: ColumnsKeys.PRICE,
        key: ColumnsKeys.PRICE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.FORM.DETAIL.COLUMNS.DISSCOUNT,
        dataIndex: ColumnsKeys.DISSCOUNT,
        key: ColumnsKeys.DISSCOUNT,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.FORM.DETAIL.COLUMNS.TOTAL,
        dataIndex: ColumnsKeys.TOTAL,
        key: ColumnsKeys.TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },

];

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.QTY]: SummaryType.SUM,
    [ColumnsKeys.DISSCOUNT]: SummaryType.SUM,
    [ColumnsKeys.TOTAL]: SummaryType.SUM,
}

export enum PaymentStatusId {
    PENDING = 1,
    PAYED = 2,
}

export enum PaymentTypeId {
    CASH = 1,
    CREDIT = 2,
}