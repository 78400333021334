import { Modal } from "antd"
import { ProductTransactionType } from "../ProductTransactionModal/types"
import { PaymentForm } from "./PaymentForm"
import { PaymentTransaction } from "./PaymentForm/types"
import { TransactionPayment } from "src/common/models/transactionPayment"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_PURCHASE_PAYMENT_API } from "src/features/Purchase/state/actionTypes"
import { createPurchasePaymentApi } from "src/features/Purchase/state/action"
import { Loader } from "../Loader"
import { useEffect, useState } from "react"
import { PurchaseError, PurchaseErrorOrigin } from "src/features/Purchase/state/types"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { ModalSuccess } from "../ModalSuccess"
import { SystemDescriptions } from "src/common/descriptions"
import { createSalePaymentApi } from "src/features/Sale/state/action"
import { CREATE_SALE_PAYMENT_API } from "src/features/Sale/state/actionTypes"
import { SaleError, SaleErrorOrigin } from "src/features/Sale/state/types"

interface Props {
    open: boolean
    type: ProductTransactionType
    onAccept: () => void
    onCancel: () => void
    data: PaymentTransaction
}

interface ReduxProps {
    isCreatingPurchasePayment: boolean
    isCreatingSalePayment: boolean
    createPurchasePaymentSuccess: boolean
    createSalePaymentSuccess: boolean
    error?: PurchaseError
    saleError?: SaleError
}

export const PaymentModal = (props: Props) => {
    const dispatch = useDispatch()

    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string | undefined>(undefined)
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [paymentData, setPaymentData] = useState<TransactionPayment | undefined>(undefined)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isCreatingPurchasePayment: serviceLoading(state, [CREATE_PURCHASE_PAYMENT_API]),
        createPurchasePaymentSuccess: state.purchase.createPurchasePaymentSuccess,
        isCreatingSalePayment: serviceLoading(state, [CREATE_SALE_PAYMENT_API]),
        createSalePaymentSuccess: state.sale.createSalePaymentSuccess,
        error: state.purchase.error,
    }))

    const descriptions = SystemDescriptions.COMPONENTS.MODAL_PAYMENT
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    // useEffect(() => {
    //     if (
    //         (reduxProps.createPurchasePaymentSuccess || reduxProps.createSalePaymentSuccess)
    //         && actionButtonClicked) {
    //         props.onAccept()
    //     }
    // }, [reduxProps.createPurchasePaymentSuccess, reduxProps.createSalePaymentSuccess])

    useEffect(() => {
        if (
            (reduxProps.error && reduxProps.error.type === PurchaseErrorOrigin.CREATE_PURCHASE_PAYMENT)
            ||
            (reduxProps.saleError && reduxProps.saleError.type === SaleErrorOrigin.CREATE_SALE_PAYMENT)
        ) {
            setErrMsg(commonDescriptions.FORM.UNKNOWN_ERROR)
        }
    }, [reduxProps.error])

    const handleOnFinish = (paymentData: TransactionPayment) => {
        setPaymentData(paymentData)
        setModalConfirmationOpen(true)
    }

    const handleCancel = () => {
        setModalCancelOpen(true)
    }

    const renderForm = () => {
        return <PaymentForm
            onFinish={handleOnFinish}
            onCancel={handleCancel}
            currentData={props.data}
            errorMessage={errMsg}
            type={props.type}
        />
    }

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)

        if (!paymentData) {
            // TODO: SHOW A TOAST OR SIMILAR
            return
        }

        if (props.type === ProductTransactionType.PURCHASE) {
            dispatch(createPurchasePaymentApi(paymentData))
        } else if (props.type === ProductTransactionType.SALE) {
            dispatch(createSalePaymentApi(paymentData))
        }
    }

    const handleCancelAccept = () => {
        setModalCancelOpen(false)
        props.onCancel()
    }

    const handleSuccessAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(false)
        props.onAccept()
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setModalConfirmationOpen(false)
                }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CONFIRM_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={(reduxProps.createPurchasePaymentSuccess || reduxProps.createSalePaymentSuccess) && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleSuccessAccept}
                onSecondaryActiont={handleSuccessAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    return (
        <>
            <Modal
                centered
                open={props.open}
                width={1000}
                footer={null}
                destroyOnClose
                title={props.type === ProductTransactionType.PURCHASE
                    ? descriptions.TITLE_PURCHASE
                    : descriptions.TITLE_SALE
                }
                onCancel={props.onCancel}
            >
                {renderForm()}
            </Modal>
            <Loader
                isVisible={reduxProps.isCreatingPurchasePayment || reduxProps.isCreatingSalePayment}
            />
            {renderModals()}
        </>
    )
}
