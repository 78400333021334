import { Modal, Spin } from "antd"
import { SystemDescriptions } from "src/common/descriptions"
import * as Styles from './styles'

type Props = {
    isVisible: boolean
    title?: string
    description?: string
}

export const Loader = (props: Props) => {

    const descriptions = SystemDescriptions.COMPONENTS.LOADER

    return (
        <Modal
            open={props.isVisible}
            closable={false}
            footer={null}
            zIndex={1100}
            centered
        >
            <Styles.Container>
                <Styles.Title>
                    {props.title || descriptions.TITLE} &nbsp; <Spin size="small" />
                </Styles.Title>

                <Styles.Description>
                    {props.description || descriptions.DESCRIPTION}
                </Styles.Description>
            </Styles.Container>
        </Modal>
    )
}
