import { Button } from 'antd'
import * as Styles from './styles'

interface Props {
    onClick: () => void
    text: string
    buttonText: string
}

export const LabelWithButton = (props: Props) => {
    return (
        <Styles.Container tabIndex={-1}>
            <span>
                {props.text}
            </span>
            {
                props.buttonText.trim().length > 0 &&
                <Button
                    size='small'
                    onClick={props.onClick}
                    tabIndex={-1}
                >
                    {props.buttonText}
                </Button>
            }
        </Styles.Container>
    )
}
