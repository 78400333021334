import { call, put, takeLatest, } from 'redux-saga/effects';
import { Urls } from 'src/common/urls';
import * as Actions from './action';
import * as ActionTypes from './actionTypes';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { getErrorFromResponse } from 'src/features/Security/networking/util';

function* getCustomersApi() {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.CUSTOMER.GET_RECORDS())
        yield put(Actions.getCustomersApiSuccess(response.data))
    } catch (err) {
        yield put(Actions.getCustomersApiError(err));
    }
}

function* createCustomerApi(action: Actions.UpsertCustomerAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.CUSTOMER.CREATE, action.customer)
        yield put(Actions.upsertCustomerApiSuccess(response.data))
    } catch (err) {
        yield put(Actions.upsertCustomerError(getErrorFromResponse(err)));
    }
}

function* getCustomerByIdApi(action: Actions.GetCustomerByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.CUSTOMER.GET_BY_ID(action.id))
        yield put(Actions.getCustomerByIdApiSuccess(response.data))
    } catch (err) {
        yield put(Actions.getCustomerByIdError(getErrorFromResponse(err)));
    }
}

export default function* securitySagas() {
    yield takeLatest(ActionTypes.GET_CUSTOMERS_API, getCustomersApi)
    yield takeLatest(ActionTypes.UPSERT_CUSTOMER_API, createCustomerApi)
    yield takeLatest(ActionTypes.GET_CUSTOMER_BY_ID_API, getCustomerByIdApi)
}