import { SystemDescriptions } from "src/common/descriptions";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.CUSTOMER.CUSTOMERS

export enum ColumnsKeys {
    NO = 'key',
    CUSTOMER = 'clientName',
    TRADE_NAME = 'tradeName',
    INVOICE_RECEIVER_ID = 'nit',
    PHONE = 'phone',
    EMAIL = 'EMAIL',
    ACTIONS = 'ACTIONS',
}

const isValueEmpty = (value: string | undefined, customValue?: string) => {
    const emptyValue: string = customValue ?? '-'

    if (!value) {
        return emptyValue
    }

    if (value.trim().length === 0) {
        return emptyValue
    }

    return value
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.CUSTOMER,
        dataIndex: ColumnsKeys.CUSTOMER,
        key: ColumnsKeys.CUSTOMER,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.TRADE_NAME,
        dataIndex: ColumnsKeys.TRADE_NAME,
        key: ColumnsKeys.TRADE_NAME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => isValueEmpty(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.PHONE,
        dataIndex: ColumnsKeys.PHONE,
        key: ColumnsKeys.PHONE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => isValueEmpty(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.EMAIL,
        dataIndex: ColumnsKeys.EMAIL,
        key: ColumnsKeys.EMAIL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => isValueEmpty(value)
    },
    {
        title: descriptions.TABLE_COLUMNS.INVOICE_RECEIVER_ID,
        dataIndex: ColumnsKeys.INVOICE_RECEIVER_ID,
        key: ColumnsKeys.INVOICE_RECEIVER_ID,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => isValueEmpty(value, 'CF')
    },
];