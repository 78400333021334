import { Languages } from "src/common/descriptions/types";
import { CustomersDescriptions } from "./types";

const DescriptionsEnUs: CustomersDescriptions = {
    TITLE: "Cusstomers",
    TABLE: {
        ACTION_BUTTON: "Create Customer",
        SEARCH_PLACEHOLDER: "Search Customer",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        ID: "No",
        CUSTOMER: "Customer",
        TRADE_NAME: "Trade Name",
        PHONE: "Phone",
        EMAIL: "Email",
        INVOICE_RECEIVER_ID: "NIT",
        ACTIONS: "Actions",
    },
}

const DescriptionsEsUs: CustomersDescriptions = {
    TITLE: "Clientes",
    TABLE: {
        ACTION_BUTTON: "Crear Cliente",
        SEARCH_PLACEHOLDER: "Buscar Cliente",
        LOADING_DATA: "Cargando datos...",
    },
    TABLE_COLUMNS: {
        ID: "No",
        CUSTOMER: "Cliente",
        TRADE_NAME: "Razón Social",
        PHONE: "Teléfono",
        EMAIL: "Correo",
        INVOICE_RECEIVER_ID: "NIT",
        ACTIONS: "Acciones",
    },
}

export const customersDictionary = {}
customersDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
customersDictionary[Languages.SPANISH_US] = DescriptionsEsUs